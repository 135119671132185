





























import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
@Observer
@Component({
  components: {},
})
export default class Character extends Vue {
  tribes = [
    { name: '1', color: '#E39345', logo: '' },
    { name: '2', color: '#223D6C', logo: '' },
    { name: '3', color: '#18411A', logo: '' },
    { name: '4', color: '#671212', logo: '' },
    { name: '5', color: '#422278', logo: '' },
  ]
}
